import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

//https://pixabay.com/users/adege-4994132/
import Pear from "../assets/videos/optim/pear.mp4";
import Cherry from "../assets/videos/optim/cherry.mp4";
import Hibiscus from "../assets/videos/optim/hibiscus.mp4";
import Daffodil from "../assets/videos/optim/daffodil.mp4";
import Crocus from "../assets/videos/optim/crocus.mp4";

import PearThumb from "../assets/images/video-thumbs/pear.jpg";
import CherryThumb from "../assets/images/video-thumbs/cherry.jpg";
import HibiscusThumb from "../assets/images/video-thumbs/hibiscus.jpg";
import DaffodilThumb from "../assets/images/video-thumbs/daffodil.jpg";
import CrocusThumb from "../assets/images/video-thumbs/crocus.jpg";

import "../assets/scss/components/_home-callout.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import Field from "./Field";
import { Image } from "./Image";
import Config from "../Config";

export const HomeCallout = () => {
	const { cloudinaryVideoUrl, baseUrl } = Config;

	const navigate = useNavigate();

	let videos = [Crocus, Pear, Cherry, Hibiscus, Daffodil];
	let videoThumbs = [CrocusThumb, PearThumb, CherryThumb, HibiscusThumb, DaffodilThumb];

	const videoElement = useRef(null);

	const [videoKey, setVideoKey] = useState(Math.floor(Math.random() * videos.length));

	const [HomeCalloutSearchValue, setHomeCalloutSearchValue] = useState("");

	const randomVideo = () => {
		let newVideoKey = Math.floor(Math.random() * videos.length);

		if (newVideoKey === videoKey) {
			return randomVideo();
		} else {
			return newVideoKey;
		}
	};

	const onFinish = () => {
		videoElement.current.classList.add("fade-out");
		setTimeout(() => {
			videoElement.current.classList.remove("fade-out");
			setVideoKey(randomVideo());
		}, 1000);
	};

	useEffect(() => {
		videoElement.current.playbackRate = 1;
	}, [videoElement, videoKey]);

	const cloudinaryVideo = (src) => {
		// Loading locally
		if (src.includes("/src/assets")) {
			return src;
		}
		return `${cloudinaryVideoUrl}/${baseUrl}/${src}`;
	};

	return (
		<div className="home-callout">
			<div className="home-callout-content">
				<div className="quick-links">
					<Link
						to="/most-popular"
						className="quick-link emphasis"
					>
						Popular
					</Link>
					<Link
						to="/next-day-flowers"
						className="quick-link"
					>
						All Flowers
					</Link>
					<Link
						to="/romantic-flowers"
						className="quick-link"
					>
						Romantic Flowers
					</Link>
					<Link
						to="/plants"
						className="quick-link"
					>
						Plants
					</Link>
					<Link
						to="/flower-gift-sets"
						className="quick-link"
					>
						Gifts
					</Link>
				</div>
				<div className="text-content">
					<h1>Explore Our Range Of Bouquets & Gifts</h1>
					<p>Beautifully delivered for free, easy as 123</p>

					<div className="search-bar">
						<Field
							label="Search Bouquets & Gifts..."
							value={HomeCalloutSearchValue}
							onChange={(e) => {
								setHomeCalloutSearchValue(e.target.value);
							}}
							onKeyDown={(e) => {
								if (e.key === "Enter" && HomeCalloutSearchValue !== "") {
									navigate(`/search/${HomeCalloutSearchValue}`);
								}
							}}
							type="text"
						/>

						<button
							className={`search-button ${HomeCalloutSearchValue !== "" ? "active" : ""}`}
							aria-label="Search"
							onClick={() => {
								if (HomeCalloutSearchValue !== "") {
									navigate(`/search/${HomeCalloutSearchValue}`);
								}
							}}
						>
							<FontAwesomeIcon icon={faCheck} />
						</button>
					</div>
				</div>

				<div className="trending-searches">
					<p>Trending Searches:</p>
					<Link to="/search/Red%20Roses">Red Roses</Link>
					<Link to="/search/Lilies">Lilies</Link>
					<Link to="/search/Germini">Germini</Link>
				</div>
			</div>

			<div
				className="video-container"
				aria-hidden="true"
			>
				<video
					className="home-callout-video"
					poster={Image({ src: videoThumbs[videoKey], returnOnly: true })}
					autoPlay
					muted
					src={cloudinaryVideo(videos[videoKey])}
					onEnded={() => {
						onFinish();
					}}
					controlsList="nofullscreen"
					playsInline
					ref={videoElement}
					preload="auto"
				/>
			</div>
		</div>
	);
};
