import React, { lazy, Suspense } from "react";

import { Route, createBrowserRouter, createRoutesFromElements, Navigate } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import Config from "../Config";

import { LoadingAnimation } from ".";
import { UrlHandler } from "../pages/UrlHandler";
import { Home } from "../pages/Home";
import PaymentGif from "../assets/images/payment.gif";

import { RequireLogin } from "./RequireLogin";

const Search = lazy(() => import("../pages/Search"));

//cart
const CartSummary = lazy(() => import("../pages/CartSummary"));
const CartPaymentSuccess = lazy(() => import("../pages/CartPaymentSuccess"));

//account
const Account = lazy(() => import("../pages/Account"));
const Orders = lazy(() => import("../pages/Orders"));
const Order = lazy(() => import("../pages/Order"));
const Login = lazy(() => import("../pages/Login"));
const Register = lazy(() => import("../pages/Register"));
const AccountSubscriptions = lazy(() => import("../pages/AccountSubscriptions"));
const Subscriptions = lazy(() => import("../pages/Subscriptions"));
const ContactUs = lazy(() => import("../pages/ContactUs"));
const ForgottenPassword = lazy(() => import("../pages/ForgottenPassword"));
const ForgottenPasswordReset = lazy(() => import("../pages/ForgottenPasswordReset"));
const MyMessage = lazy(() => import("../pages/MyMessage"));

//static
const AboutUs = lazy(() => import("../pages/AboutUs"));

export const RoutesComponent = ({ RootElement }) => {
	return createBrowserRouter(
		createRoutesFromElements(
			<Route
				path="/"
				element={<RootElement />}
			>
				<Route
					path="/cart"
					element={
						<Suspense fallback={<LoadingAnimation />}>
							<CartSummary />
						</Suspense>
					}
				/>
				<Route
					path="/cart/payment-success"
					element={
						<RequireLogin loginRequired={true}>
							<Suspense
								fallback={
									<LoadingAnimation
										titleOverride="We're just processing your order."
										bodyOverride={`This is taking a little while longer than usual, you can try refresh the page, you will not be charged again. If the problem persists and you still cannot load the page, please get in touch with customer service.`}
										linkOverride="/contact"
										buttonText="Contact Us"
										imageOverride={PaymentGif}
									/>
								}
							>
								<CartPaymentSuccess />
							</Suspense>
						</RequireLogin>
					}
				/>
				<Route
					path="/login"
					element={
						<RequireLogin loginRequired={false} redirectPage="/account">
							<Suspense fallback={<LoadingAnimation />}>
								<Login />
							</Suspense>
						</RequireLogin>
					}
				/>
				<Route
					path="/login/social"
					element={
						<RequireLogin loginRequired={false} redirectPage="/account">
							<Suspense fallback={<LoadingAnimation />}>
								<Login
									isSocial={true}
								/>
							</Suspense>
						</RequireLogin>
					}
				/>
				<Route
					path="/register"
					element={
						<RequireLogin loginRequired={false} redirectPage="/account">
							<Suspense fallback={<LoadingAnimation />}>
								<Register />
							</Suspense>
						</RequireLogin>
					}
				/>
				<Route
					path="/forgot-password/"
					element={
						<RequireLogin loginRequired={false} redirectPage="/account">
							<Suspense fallback={<LoadingAnimation />}>
								<ForgottenPassword />
							</Suspense>
						</RequireLogin>
					}
				/>
				<Route
					path="/forgot-password/:token"
					element={
						<RequireLogin loginRequired={false} redirectPage="/account">
							<Suspense fallback={<LoadingAnimation />}>
								<ForgottenPasswordReset />
							</Suspense>
						</RequireLogin>
					}
				/>
				<Route
					path="/account"
					element={
						<RequireLogin loginRequired={true}>
							<Suspense fallback={<LoadingAnimation />}>
								<Account />
							</Suspense>
						</RequireLogin>
					}
				/>
				<Route
					path="/account/orders"
					element={
						<RequireLogin loginRequired={true}>
							<Suspense fallback={<LoadingAnimation />}>
								<Orders />
							</Suspense>
						</RequireLogin>
					}
				/>
				<Route
					path="/account/orders/:orderId"
					element={
						<RequireLogin loginRequired={true}>
							<Suspense fallback={<LoadingAnimation />}>
								<Order />
							</Suspense>
						</RequireLogin>
					}
				/>
				<Route
					path="/account/subscriptions"
					element={
						<RequireLogin loginRequired={true}>
							<Suspense fallback={<LoadingAnimation />}>
								<AccountSubscriptions />
							</Suspense>
						</RequireLogin>
					}
				/>
				<Route
					path="/subscriptions"
					element={
						<Suspense fallback={<LoadingAnimation />}>
							<Subscriptions />
						</Suspense>
					}
				/>
				<Route
					path="/"
					element={<Home />}
				/>
				<Route
					path="/search/:search"
					loader={({ params }) => {
						return params.search;
					}}
					element={
						<Suspense fallback={<LoadingAnimation />}>
							<Search />
						</Suspense>
					}
				/>
				<Route
					path="/about-us"
					element={
						<Suspense fallback={<LoadingAnimation />}>
							<AboutUs />
						</Suspense>
					}
				/>
				<Route
					path={"contact-us"}
					element={
						<Suspense fallback={<LoadingAnimation />}>
							<GoogleReCaptchaProvider reCaptchaKey={Config.reCaptchaKey}>
								<ContactUs />
							</GoogleReCaptchaProvider>
						</Suspense>
					}
				/>
				<Route
					path="/my-message/:id/:hash"
					element={
						<Suspense fallback={<LoadingAnimation />}>
							<MyMessage />
						</Suspense>
					}
				/>
				<Route
					index
					path="/:type/:item?"
					loader={({ params }) => {
						return { type: params.type, item: params.item };
					}}
					element={<UrlHandler />}
				/>

				<Route
					path="*"
					element={<Navigate to="/" />}
				/>
			</Route>
		)
	);
};
