import { ApiRequest } from "./base";

const apiRoute = "categories";

export const CategoriesApi = {
	async getCategory(id) {
		let result = await ApiRequest(`${apiRoute}/${id}`, "GET").then(function (res) {
			if (id === "home") {
				return res.home ? res.home : {};
			} else {
				return res.categories ? res.categories : {};
			}
		});

		return result;
	},
	async getContentBlocks(device = "desktop") {
		const result = await ApiRequest(`${apiRoute}/content-blocks/${device}`, "GET");
		return result.content_blocks;
	}
};
