import React, { useState, useEffect } from "react";

import "../assets/scss/pages/_product-page.scss";

import { ProductSizes } from "../components/ProductSizes";
import { StepTracker } from "../components/StepTracker";
import { Slider } from "../components/Slider";
import { AddonSection } from "../components/AddonSection";
import { DeliverySection } from "../components/DeliverySection";
import { MessageCardSection } from "../components/MessageCardSection";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { TabbedSection } from "../components/TabbedSection";
import { ActionButtons } from "../components/ActionButtons";
import { RelatedProducts } from "../components/RelatedProducts";
import { ProductChoices } from "../components/ProductChoices";
import { Placeholder } from "../components/Placeholder";
import { AddToCartButton } from "../components/AddToCartButton";
import { LoadingAnimation } from "../components/LoadingAnimation";
import { ProductDescription } from "../components/ProductDescription";
import { AvailableFrom } from "../components/AvailableFrom";
import { MiniCartItem } from "../components/MiniCartItem";
import { BraintreeDropin } from "../components/BraintreeDropin";
import { ProductImage } from "../components/ProductImage";
import { FullReviewSection } from "../components/FullReviewSection";

import { faSun, faTint, faThermometerHalf, faLeaf, faArrowLeft } from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useProductStore } from "../stores/ProductStore";

import { useParams } from "react-router-dom";
import { useCartStore } from "../stores/CartStore";
import { useUserStore } from "../stores/UserStore";
import { useMetaStore } from "../stores/MetaStore";

import { useNavigate } from "react-router-dom";

// import TagManager from "react-server-side-gtm";

import { Link } from "react-router-dom";
import { useAppStore } from "../stores/AppStore";
import { PaymentOptions } from "../components/PaymentOptions";

window.dataLayer = window.dataLayer || [];

const GA4ProductData = (productInfo) => {
	// TagManager.dataLayer({
	// 	dataLayer: {
	// 		event: "view_item",
	// 		currency: "GBP",
	// 		ecommerce: {
	// 			detail: {
	// 				products: {
	// 					name: productInfo.products_name,
	// 					id: productInfo.products_id,
	// 					price: productInfo.price_retail,
	// 					category: productInfo.product_type,
	// 					delivery_availability: productInfo.delivery?.lead_time,
	// 					product_group: "base",
	// 				},
	// 			},
	// 		},
	// 	},
	// });
	window.dataLayer.push({
		event: "view_item",
		currency: "GBP",
		ecommerce: {
			detail: {
				products: {
					name: productInfo.products_name,
					id: productInfo.products_id,
					price: productInfo.price_retail,
					category: productInfo.product_type,
					delivery_availability: productInfo.delivery?.lead_time,
					product_group: "base",
				},
			},
		},
	});
};

export const ProductPage = ({ id = "no-id" }) => {
	const params = useParams();
	if (id === "no-id") {
		id = params.id;
	}

	let dummyData = false;

	const [secondStage, setSecondStage] = useState(false);
	const [formFailed, setFormFailed] = useState(false);
	const [showFullAddress, setShowFullAddress] = useState(false);

	const [loaded, setLoaded] = useState(false);

	const [paymentFormShown, setPaymentFormShown] = useState(false);

	const { info, GetInfo, price, SetCurrentProductStep, AddToCart, address, productSteps, relatedProducts, selectedAddons, selectedDeliveryDate, selectedDeliveryOption, selectedSize, messageCardText, originalPrice, msrpPrice, SetAddedToCart, cartId, printzwareId } = useProductStore((state) => state);

	const { cart, totals } = useCartStore(({ cart, totals }) => {
		return { cart, totals };
	});

	const { userInfo } = useUserStore(({ userInfo }) => {
		return { userInfo };
	});

	const navigate = useNavigate();

	useEffect(() => {
		if (id !== undefined && id !== 0) {
			GetInfo(id, true);
		}
	}, [GetInfo, id]);

	useEffect(() => {
		try {
			document.querySelector(".main-content").scrollTo({ top: 0 });
		} catch (error) {
			console.error("Error scrolling to top of .main-content", error);
		}

		try {
			document.querySelector("html").scrollTo({ top: 0 });
		} catch (error) {
			console.error("Error scrolling to top of html", error);
		}
	}, [secondStage]);

	useEffect(() => {
		if (info.id && info.id !== 0) {
			setLoaded(true);
			// useMetaStore.getState().SetTitle(info.products_name);
			useMetaStore.getState().SetMeta({
				title: info.products_name,
				description: info.quote && info.quote !== "" ? info.quote : info.short_description && info.short_description !== "" ? info.short_description : info.full_description && info.full_description !== "" ? info.full_description : "A beautiful hand tied bouquet",
				image: info.images && info.images[0] ? info.images[0].location : "",
				keywords: info.meta.keywords ?? "",
				h1: info.meta.h1_content !== "" ? info.meta.h1_content : info.products_name,
				h2: info.meta.h2_content ?? "",
				footerContent: info.meta.footer_content !== "" ? info.meta.footer_content : info.quote && info.quote !== "" ? info.quote : info.short_description && info.short_description !== "" ? info.short_description : info.full_description && info.full_description !== "" ? info.full_description : "A beautiful hand tied bouquet",
			});
		}
	}, [info, loaded]);

	useEffect(() => {
		if (loaded === true && info) {
			try {
				GA4ProductData(info);
			} catch (error) {
				console.error("Error setting GA4 data", error);
			}
		}
	}, [loaded, info]);

	if (!info) {
		return <LoadingAnimation pageType="Product Page" />;
	}

	let in_stock = true;
	in_stock = info.stock > 0 ? in_stock : false;
	in_stock = info && info.delivery && info.delivery.upcoming_dates && info.delivery.upcoming_dates.length > 0 ? in_stock : false;

	let images = { ...info.images };
	// delete images[0]; //commented out as we want the first image as well until reshoot

	if (useAppStore.getState().isDebug) {
		console.log("ProductPage.jsx - Product info", info);
	}

	images = dummyData
		? {
				0: {
					location: "https://images.prestigeflowers.co.uk/fetch/w_400,e_sharpen:80,q_auto,f_auto,dpr_auto,f_auto/https://www.prestigeflowers.co.uk/images/products/9c88cdaa0d6f23333564826e2ee15e8a.jpg",
				},
				1: {
					location: "https://images.prestigeflowers.co.uk/fetch/w_400,e_sharpen:80,q_auto,f_auto,dpr_auto,f_auto/https://www.prestigeflowers.co.uk/images/products/ed9ad31cb13a9cf9510ab115c05befce.jpg",
				},
				2: {
					location: "https://images.prestigeflowers.co.uk/fetch/w_400,e_sharpen:80,q_auto,f_auto,dpr_auto,f_auto/https://www.prestigeflowers.co.uk/images/products/b71ad05595ee0987863626f812e4699c.jpg",
				},
		  }
		: images;

	const AddToCartCheck = (showButton = true) => {
		if (!address) return;

		const fields = ["address_name", "address_postcode", "address_line1", "address_town", "address_county", "address_country"];

		const validChecks = fields.length;
		const validConfirm = fields.filter((field) => address[field] && address[field] !== "").length;

		if (validChecks === validConfirm) {
			setFormFailed(false);
			AddToCart({ showButton: showButton }).then((r) => {
				SetAddedToCart(r.length - 1);

				if (window.innerWidth <= 768) {
					navigate("/cart");
				}
			});

			return true;
		} else {
			setFormFailed(true);
			setShowFullAddress(true);
			document.querySelector(".delivery-section").scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
		}

		return false;
	};

	return (
		loaded === true && (
			<div
				className={`product-page container ${secondStage ? "second-stage" : "first-stage"} ${formFailed && "form-failed"}`}
				id={info.id}
			>
				<div className="row align-items-stretch justify-content-md-space-between justify-content-center">
					<div className={`col-12 ${secondStage ? "col-md-8" : "col-md-7"} description-container`}>
						{!secondStage ? (
							<>
								<ProductDescription
									shortDescription={info.short_description}
									fullDescription={info.full_description}
									id={info.id}
									sku={info.sku}
								/>
								{Object.keys(images).length > 0 && (
									<section className="slider-section additional-images-section">
										<Slider
											slides={Object.keys(images).map((image, index) => {
												return { image: images[image].location, imageWidth: 500 };
											})}
											prefix="additional-images"
											arrows={true}
											slides_per_row={{ base: 1, md: 2 }}
											indicators={true}
											lightbox={true}
										/>
									</section>
								)}
								<FullReviewSection />

								{info.product_type && info.product_type === "Flowers" && (
									<>
										<TabbedSection
											prefix="flower-care"
											title="For me to last as long as possible I need:"
											tabs={[
												{
													title: "Flower Care",
													icon: faLeaf,
													content: ["Clean vase: Start with a clean vase to prevent the growth of bacteria that can damage the flowers. Wash the vase with mild soap and warm water, then rinse thoroughly before use.", "Remove foliage: Strip off any foliage or leaves that would be submerged in water. Foliage left in the water can decompose, leading to bacterial growth and shortening the lifespan of the flowers.", "Trim the stems: Using sharp, clean scissors or pruning shears, trim about an inch off the bottom of each stem at a diagonal angle. This allows the flowers to absorb water more effectively.", "Flower food: If provided with the bouquet or available separately, consider using flower food. Follow the instructions on the packet to mix it with the water. Flower food contains nutrients that help nourish the flowers and extend their longevity."],
												},
												{
													title: "Light",
													icon: faSun,
													content: ["Indirect sunlight: Place the bouquet in an area that receives bright, indirect sunlight. Direct exposure to intense sunlight can cause the flowers to wilt or fade prematurely. However, a moderate amount of sunlight is beneficial for their growth and freshness.", "Consistent lighting: Maintain a consistent lighting environment for the bouquet. Frequent changes in lighting conditions, such as moving the flowers from a dark room to a brightly lit area, can disrupt their blooming patterns and overall health.", "Rotate the arrangement: To ensure even exposure to light, periodically rotate the bouquet. This practice prevents one side of the flowers from receiving more light than the other, promoting balanced growth and preventing leaning or uneven blooming."],
												},
												{
													title: "Water",
													icon: faTint,
													content: ["Fresh water: Fill the vase with fresh, room temperature water. Avoid using hot or cold water as extreme temperatures can shock the flowers.", "Water level: Maintain an appropriate water level in the vase. Ideally, the stems should be submerged in water about two-thirds of their length. Check the water level daily and replenish as needed.", "Change water regularly: Every two to three days, empty the vase, rinse it thoroughly, and refill it with fresh water. This prevents the buildup of bacteria and keeps the water clear and oxygenated, prolonging the life of the bouquet."],
												},
												{
													title: "Temperature",
													icon: faThermometerHalf,
													content: ["Optimal room temperature: Keep the bouquet in an environment with a moderate room temperature, ideally between 18°C and 24°C. Avoid exposing the flowers to extreme temperatures, such as direct sunlight or drafts from air conditioning units, heating vents, or open windows.", "When changing the water in the vase, use room temperature water. Drastic temperature changes can shock the flowers and hinder their ability to absorb water effectively."],
												},
											]}
										/>
									</>
								)}

								<RelatedProducts info={relatedProducts} />
							</>
						) : (
							<>
								{window.innerWidth < 768 && (
									<button
										className="btn back-to-product-information"
										onClick={() => {
											setSecondStage(false);
										}}
									>
										<FontAwesomeIcon icon={faArrowLeft} /> Back To Product Information
									</button>
								)}
								{info.has_sizes === "Yes" && in_stock && window.innerWidth < 768 && <ProductSizes />}
								<AddonSection />
								<DeliverySection
									showFullAddress={showFullAddress}
									setShowFullAddress={setShowFullAddress}
								/>
								<MessageCardSection />
								<section className="add-to-cart-section">
									<div className="recap">
										<strong className="section-title">Your final product configuration:</strong>
										<p>For more details please check the sidebar featured to the left.</p>
									</div>

									<MiniCartItem
										image={info?.images?.[0]?.location.replace(".local", ".co.uk")}
										productName={info.products_name}
										price={price}
										size={selectedSize}
										deliveryDate={selectedDeliveryDate}
										deliveryAddress={address.address_name && address.address_line1 && address.address_town && address.address_postcode ? `${address.address_name} at ${address.address_line1}, ${address.address_town}, ${address.address_postcode}` : ""}
										deliveryType={selectedDeliveryOption ? selectedDeliveryOption.name.split("-")[0] : ""}
										addons={selectedAddons}
										removeButtonShown={false}
										messageCard={messageCardText}
										personalisedCardID={printzwareId}
									/>

									{formFailed && <p className="form-failed">Please fill in all required fields to add the product to the basket.</p>}
									{Object.keys(cart).length === 0 && !paymentFormShown && (
										<button
											className="buy-now-button emphasis-button"
											onClick={() => {
												let res = AddToCartCheck(false);
												if (res === true) {
													setPaymentFormShown(true);
												}
											}}
										>
											Buy Now
										</button>
									)}

									<div className="payment-form">
										{paymentFormShown === "loading" && <LoadingAnimation />}

										{paymentFormShown === true && (
											<PaymentOptions
												cart={cart}
												userInfo={userInfo}
												totals={totals}
											/>
										)}
									</div>

									{paymentFormShown === true && (
										<div className="or">
											<hr />
											OR
											<hr />
										</div>
									)}

									{cartId === false ? (
										<button
											onClick={() => {
												AddToCartCheck();
											}}
											className={`add-to-basket ${paymentFormShown ? "faded" : ""} ${Object.keys(cart).length > 0 ? "emphasis-button" : ""}`}
										>
											Add To Basket &amp; Continue Shopping
										</button>
									) : (
										<>
											{Object.keys(cart).length !== 0 && !paymentFormShown && <div className="alert">Your product has been added to the basket now, please double check the information presented above and make any ammendments needed.</div>}

											<Link
												to="/cart"
												className="btn"
											>
												View Your Cart
											</Link>
										</>
									)}
								</section>
							</>
						)}
					</div>

					<div className={`col-12 ${secondStage ? "col-md-4" : "col-md-5"} row column justify-content-start details-sidebar`}>
						<div className="details-container row">
							{!secondStage && <Breadcrumbs productName={info.products_name ?? ""} />}
							<div className="product-details col-12">
								<div className="row align-items-center">
									<div className="col-fluid">
										<h1>{info.products_name ?? " "} </h1>
									</div>

									{in_stock && (
										<div className="col mob-only mobile-pricing row justify-content-lg-space-between align-items-end">
											{parseFloat(info.price_msrp) > parseFloat(info.price_retail) && <div className="msrp">£{parseFloat(msrpPrice).toFixed(2)}</div>}
											<div className="retail">£{parseFloat(originalPrice).toFixed(2)}</div>
										</div>
									)}

									{!secondStage && (
										<div className="col">
											<ActionButtons
												pos="right"
												product_id={info.id ?? 0}
												rating={info.review_average ?? 0}
											/>
										</div>
									)}
								</div>
								<strong className="tagline">{info?.product_type === "Plants" ? "Perfectly Potted Plant" : info?.product_type === "Hampers" ? "Charming Gift Baskets" : "Hand Tied Bouquet"} </strong>

								{info.quote && info.quote !== "" && <p className="quote">{info.quote}</p>}

								{!secondStage && info.has_sizes === "Yes" && in_stock && <ProductSizes />}

								{!secondStage && (
									<AddToCartButton
										inStock={in_stock}
										info={info}
										setSecondStage={setSecondStage}
										secondStage={secondStage}
									/>
								)}
								{!secondStage && (
									<strong className="delivery-from">
										<AvailableFrom date={info.stock > 0 && info.first_available_delivery !== "" ? info.first_available_delivery : false} />
									</strong>
								)}
							</div>
							<div className="row product-info justify-content-space-between step-container col-12">
								<div className="col-12 col-md-7 row column justify-content-end">
									<StepTracker
										productSteps={productSteps}
										setSecondStage={setSecondStage}
									/>
								</div>
								{!secondStage && (
									<div className="col-12 col-md-3 row column justify-content-end image-container">
										{dummyData && dummyData === true ? (
											<Placeholder check={null}>
												<img alt="Main Product" />
											</Placeholder>
										) : (
											<Placeholder check={null}>{info.images && <ProductImage image={info?.images[0]?.location} />}</Placeholder>
										)}
									</div>
								)}
							</div>
							{secondStage && (
								<section className="second-stage-sidebar-content col-12">
									<ProductChoices
										setSecondStage={setSecondStage}
										SetCurrentProductStep={SetCurrentProductStep}
									/>
									<div className="product-totals">
										<h3>
											Total price: <span>£{parseFloat(price).toFixed(2)}</span>
										</h3>
									</div>
								</section>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	);
};

export default ProductPage;
