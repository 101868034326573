import React from "react";
import { Helmet } from "react-helmet";

import { useMetaStore } from "../stores/MetaStore";

export const Head = () => {
	const { image } = useMetaStore();

	return (
		<Helmet>
			<title>{useMetaStore.getState().title}</title>
			<meta
				name="description"
				content={useMetaStore.getState().description}
			/>

			<meta
				property="og:title"
				content={useMetaStore.getState().title}
			/>
			<meta
				property="og:description"
				content={useMetaStore.getState().description}
			/>

			<meta
				name="keywords"
				content={useMetaStore.getState().keywords}
			/>

			{image && (
				<meta
					property="og:image"
					content={useMetaStore.getState().image.replace(".local", ".co.uk")}
				/>
			)}
		</Helmet>
	);
};

export default Head;
