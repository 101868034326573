const log = (config) => (set, get, api) =>
	config(
		(...args) => {
			set(...args);
		},
		get,
		api
	);

export { log };
