import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";

import { Link } from "react-router-dom";

import HeaderImg from "../assets/images/lifestyles/autumn-flowers-lifestyle-new.jpeg";

import "../assets/scss/components/_home-upsell.scss";

import { Image, Slider } from ".";

export const HomeUpsell = () => {
	return (
		<section
			className="home-upsell"
			fetchpriority="high"
		>
			<div
				className="biggest-trends"
				style={
					{
						// backgroundImage: `url(${HeaderImg})`,
						// backgroundSize: "55%"
					}
				}
			>
				<div className="upsell-img">
					<Image
						src={HeaderImg}
						alt="Sunflower Season"
						aria-label="Sunflower Season"
						imported={true}
					/>
				</div>

				<div className="container">
					<div className="row justify-content-lg-space-between">
						<div className="col-md-4 trend-description">
							<strong className="upsell-title">Autumn Flowers</strong>

							<p>Capture the warmth of the season with our beautiful range of Autumnal bouquets beautifully created to bring the stunning colours and hues of Autumn into your home including stunning rich oranges, deep reds and vibrant yellows.</p>

							<Link
								to="/autumn-flowers"
								className="btn minimal"
							>
								Shop Now <FontAwesomeIcon icon={faArrowRight} />
							</Link>
						</div>
					</div>
				</div>
			</div>

			<div className="category-upsell">
				<div className="container">
					<Slider
						slides={[
							{
								element: (
									<div>
										<strong>Send Some Luxury</strong>
										<p>Our Flowers By Rene Collection is perfect for those extra special occasions. Unique arrangements using the finest stems perfect for birthdays to anniversaries.</p>
										<Link
											to="/luxury-flowers"
											className="btn minimal"
										>
											Shop Now <FontAwesomeIcon icon={faArrowRight} />
										</Link>
									</div>
								),
							},
							{
								element: (
									<div>
										<strong>Subscribe &amp; Save</strong>
										<p>Our subscription service is perfect to fill your home with seasonal bouquets either weekly, fortnightly or monthly all for £20 per delivery including free delivery.</p>
										<Link
											to="/subscriptions"
											className="btn minimal"
										>
											Shop Now <FontAwesomeIcon icon={faArrowRight} />
										</Link>
									</div>
								),
							},
							{
								element: (
									<div>
										<strong>Bouquets Through The Letterbox</strong>
										<p>Our letterbox flowers are the perfect solution for busy recipients. Our flowers are delivered in bud and straight through the letterbox.</p>
										<Link
											to="/letterbox-flowers"
											className="btn minimal"
										>
											Shop Now <FontAwesomeIcon icon={faArrowRight} />
										</Link>
									</div>
								),
							},
						]}
						slides_per_row={{ base: 1, md: 3 }}
						autoplay={true}
					/>
				</div>
			</div>
		</section>
	);
};
