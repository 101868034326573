import { ApiRequest } from "./base";

const apiRoute = "config";

export const ConfigApi = {
	async GetSession() {
		let { session_id } = await ApiRequest(`${apiRoute}/`, "GET");
		return session_id;
	}
};
