import React from "react";

import { Link } from "react-router-dom";

import "../assets/scss/components/_callout-banner.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";

export const CalloutBanner = () => {
	return (
		<section className="callout-banner">
			<Link to="/next-day-flowers">
				Seasonal bouquets with <strong>FREE DELIVERY</strong> from £19.99. Explore range now! <FontAwesomeIcon icon={faArrowRight} />
			</Link>
		</section>
	);
};
