import { create } from "zustand";
import { UserApi, LoginApi, LogoutApi, WishlistApi, RegisterApi, SubscriptionApi } from "../api";
import { devtools, persist } from "zustand/middleware";

import { useAppStore } from "./AppStore";

const initialState = {
	userInfo: {},
	wishlistInfo: {},
	subscriptions: [],
	loginFailed: false
};

const useUserStore = create(
	persist(
		devtools((set, get) => ({
			...initialState,
			async GetInfo(id) {
				try {
					const response = await UserApi.getInfo(id);

					if (typeof response === "object" && Object.keys(response).length > 0) {
						set({ userInfo: response.user_info });

						if (useAppStore.getState().isDebug) {
							console.log(`UserStore.js - User info`, response.user_info);
						}

						get().WishlistGet();
						return response.user_info;
					}

					set({ userInfo: {} });
					return {};
				} catch (error) {
					// console.error("Failed to get user info:", error);
					set({ userInfo: {} });
					return {};
				}
			},
			async Register(data) {
				const response = await RegisterApi.Register(data);

				if (useAppStore.getState().isDebug) {
					console.log(`UserStore.js - Register user`, response);
				}

				get().GetInfo();
			},
			async Login(email, password) {
				try {
					const response = await LoginApi.Login(email, password);

					if (typeof response === "object" && Object.keys(response).length === 0) {
						console.error("login failed");
						set({ loginFailed: true });
						return response;
					} else {
						if (useAppStore.getState().isDebug) {
							console.log(`UserStore.js - Login success`);
						}

						set({ userInfo: response.user_info, loginFailed: false });
						return response.user_info;
					}
				} catch (error) {
					console.error("Failed to login:", error);
					return {};
				}
			},
			async SocialLogin(provider, code) {
				try {
					const response = await LoginApi.SocialLogin(provider, code);

					if (typeof response === "object" && Object.keys(response).length === 0) {
						console.error("login failed");
						set({ loginFailed: true });
						return response;
					} else {
						if (useAppStore.getState().isDebug) {
							console.log(`UserStore.js - Social login success`);
						}

						set({ userInfo: response.user_info, loginFailed: false });
						return response.user_info;
					}
				} catch (error) {
					console.error("Failed to social login:", error);
					return {};
				}
			},
			async ForgotPassword(email) {
				try {
					const response = await LoginApi.ForgotPassword(email);
					if (response.success && response.success === true) {
						return true;
					}
				} catch (error) {
					console.error(error);
					return error;
				}
			},
			async UpdatePassword(token, password) {
				try {
					const response = await LoginApi.UpdatePassword(token, password);
					if (response.success && response.success === true) {
						return true;
					}
				} catch (error) {
					console.error(error);
					return error;
				}
			},
			async CheckPasswordToken(token) {
				try {
					const response = await LoginApi.CheckPasswordToken(token);
					if (response.success && response.success === true) {
						return true;
					}
				} catch (error) {
					console.error(error);
					return error;
				}
			},
			async Update(data) {
				await UserApi.update(data);

				if (useAppStore.getState().isDebug) {
					console.log(`UserStore.js - Updating user`, data);
				}

				get().GetInfo();
			},
			async Logout() {
				try {
					const response = await LogoutApi.Logout();
					set({ userInfo: {} });
					return response;
				} catch (error) {
					console.error("Failed to logout:", error);
					return {};
				}
			},
			async WishlistGet() {
				try {
					const response = await WishlistApi.get();

					let wishlistInfo = {};
					if (response && response.wishlist) {
						response.wishlist.forEach((item) => {
							wishlistInfo[item.product_id] = item;
						});
					}

					set({ wishlistInfo: wishlistInfo });
					return wishlistInfo;
				} catch (error) {
					console.error("Failed to get wishlist:", error);
					return {};
				}
			},
			async WishlistAdd(data) {
				try {
					if (!data.product_id) {
						throw new Error("No product id");
					}
					await WishlistApi.add(data);
					await get().WishlistGet();
				} catch (error) {
					console.error("Failed to add to wishlist:", error);
					return {};
				}
			},
			async WishlistRemove(data) {
				try {
					if (!data.product_id) {
						throw new Error("No product id");
					}
					await WishlistApi.remove(data);
					await get().WishlistGet();
				} catch (error) {
					console.error("Failed to remove from wishlist:", error);
					return {};
				}
			},
			async GetSubscriptions() {
				try {
					const { subscriptions } = await SubscriptionApi.getSubscriptions();
					set({ subscriptions });
				} catch (error) {
					console.error("Failed to get subscriptions:", error);
					return {};
				}	
			},
			async CancelSubscription(id) {
				try {
					await SubscriptionApi.cancelSubscription(id);
					await get().GetSubscriptions();
				} catch (error) {
					console.error("Failed to cancel subscription:", error);
					return {};
				}
			},
			async PauseSubscription(id) {
				try {
					await SubscriptionApi.pauseSubscription(id);
					await get().GetSubscriptions();
				} catch (error) {
					console.error("Failed to pause subscription:", error);
					return {};
				}
			},
			async ResumeSubscription(id) {
				try {
					await SubscriptionApi.resumeSubscription(id);
					await get().GetSubscriptions();
				} catch (error) {
					console.error("Failed to resume subscription:", error);
					return {};
				}
			}
		})),
		{
			name: "user-storage"
		}
	)
);

export { useUserStore };
