import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { ConfigApi } from "../api";
import Config from "../Config";

const useAppStore = create(
	persist(
		devtools((set, get) => ({
			sessionId: null,
			cacheVersion: null,
			isDebug: false,
			init: async () => {
				const sessionId = await ConfigApi.GetSession();
				const cacheVersion = Config.cacheVersion;
				set((prev) => ({
					...prev,
					sessionId,
					cacheVersion: prev.cacheVersion ? prev.cacheVersion : cacheVersion
				}));

				//check to see if cookie is set
				if (!document.cookie.includes("PHPSESSID")) {
					document.cookie = `PHPSESSID=${sessionId}`;
				}
			},
			getSessionId: () => get().sessionId,
			resetLocalStorage: () => {
				localStorage.clear();
			},
			ToggleDebugMode: () => {
				set((prev) => ({ ...prev, isDebug: !prev.isDebug }));
			},
			cacheVersionValidation: () => {
				if (Config.cacheVersion !== get().cacheVersion) {
					console.log("Version mismatch, clearing local storage.");
					get().resetLocalStorage();
					set((prev) => ({ ...prev, cacheVersion: Config.cacheVersion }));
				}
			}
		})),
		{
			name: "app-storage"
		}
	)
);

export { useAppStore };
