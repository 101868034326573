import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faShoppingBasket, faTimes } from "@fortawesome/pro-regular-svg-icons";

import { AccountContainer, MiniCartContainer, NavigationLinks, SearchBar, Slider } from ".";

import { useCartStore } from "../stores/CartStore";

import "../assets/scss/components/_header.scss";

import { useLocation } from "react-router-dom";

import Config from "../Config";

export const Header = () => {
	const [mobileNav, setMobileNav] = useState(false);

	const location = useLocation();

	const { logo } = Config;

	useEffect(() => {
		setMobileNav(false);
	}, [location]);

	const { cart } = useCartStore(({ cart }) => {
		return { cart };
	});

	return (
		<div className="header-container">
			<div className="usp-section">
				<Slider
					slides={[
						{
							element: <p>Order by 10pm for next day delivery</p>
						},
						{
							element: <p>Free delivery, 7 days a week</p>
						},
						{
							element: (
								<a
									target="_blank"
									rel="noreferrer"
									aria-label="Our Reviews on Reviews.io"
									href="https://www.reviews.co.uk/company-reviews/store/123-flowers-co-uk"
								>
									12,000+ reviews on Reviews.io <FontAwesomeIcon icon={faArrowRight} />
								</a>
							)
						}
					]}
					slide_duration={10}
					prefix="usp-slider"
					slides_per_row={1}
					autoplay={true}
					fade={true}
				/>
			</div>
			<div className="top-section container">
				<div className="row align-items-center justify-content-space-between">
					<div className="hamburger-container col-2 col-md-5">
						<div className="hamburger-wrapper">
							<button
								className="hamburger"
								id="mobile-toggle"
								aria-label="Open Menu"
								onClick={() => {
									setMobileNav(!mobileNav);
								}}
							>
								<svg
									className={`hamburger-svg ${mobileNav ? "open" : ""}`}
									viewBox="0 0 100 100"
									width="35"
									height="35"
									version="1.1"
									xmlns="http://www.w3.org/2000/svg"
									x="0px"
								>
									<line
										className="hamburger-line  hamburger-line--1"
										x1="20"
										y1="50"
										x2="80"
										y2="50"
									/>
									<line
										className="hamburger-line  hamburger-line--2"
										x1="20"
										y1="50"
										x2="80"
										y2="50"
									/>
									<line
										className="hamburger-line  hamburger-line--3"
										x1="20"
										y1="50"
										x2="80"
										y2="50"
									/>
								</svg>
							</button>
						</div>
					</div>
					<Link
						className="logo-container col-8 col-md-2"
						to="/"
					>
						<img
							src={logo}
							alt="Logo"
							width="80px"
							height="47px"
						/>
					</Link>
					<div className={`links-container row align-items-center col-12 col-md-5 ${mobileNav ? "open" : ""}`}>
						<div className="action-buttons">
							<div className="row align-items-center">
								<SearchBar />
								<MiniCartContainer />

								{/* <div className="wishlist-button-container">
									<div className="wishlist-button">
										<Link to="/wishlist">
											<FontAwesomeIcon icon={faStarShooting} />
										</Link>
									</div>
								</div> */}
							</div>
						</div>
						<AccountContainer />

						<div className="mobile-navigation-links">
							<NavigationLinks mobile={true} />
						</div>

						<div className="search-container">
							<p>Already know what you want? Search for a product instead:</p>
							<SearchBar />
						</div>
						<div className="close-button-container">
							<button
								className="close-button"
								onClick={() => {
									setMobileNav(false);
								}}
							>
								<FontAwesomeIcon icon={faTimes} /> Close Menu
							</button>
						</div>
					</div>
					<div
						className={`link-blackout ${mobileNav ? "active" : ""}`}
						onClick={() => {
							setMobileNav(false);
						}}
					></div>
					<div className="col-2">
						<div className={`mobile-cart-link ${cart && cart.length > 0 ? "indicator-shown" : ""}`}>
							{cart && cart.length > 0 && <div className="cart-length">{cart.length}</div>}
							<Link to="/cart">
								<FontAwesomeIcon icon={faShoppingBasket} />
							</Link>
						</div>
					</div>
				</div>
			</div>
			<div className="navigation-bar">
				<div className="container">
					<div className="row align-items-center justify-content-start">
						<NavigationLinks mobile={false} />
					</div>
				</div>
			</div>
		</div>
	);
};
